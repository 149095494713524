import React, { useEffect, useState } from 'react';
import getAllSchedulingItems from '../../../helper/user/getAllSchedulingItems';
import getAllScheduling from '../../../helper/user/getAllScheduling';
import deleteSchedulingItems from '../../../helper/user/deleteSchedulingItems';
import updateScheduling from '../../../helper/user/updateScheduling';
import { useParams } from 'react-router-dom';
import ExcelJS from 'exceljs';
import Cookies from 'js-cookie';
import isLogged from '../../../helper/user/telegram/isLogged';
import io from 'socket.io-client';
import { useNavigate, useLocation } from 'react-router-dom';

const SendView = () => {
    const { sendId } = useParams();
    const [loading, setLoading] = useState(false);
    const [allData, setAllData] = useState([{}]);
    const [buttonStatus, setButtonStatus] = useState({});
    const [message, setMessage] = useState('');
    const [image, setImage] = useState(false);
    const [maximumSender, setMaximumSender] = useState(1);
    const [plan, setPlan] = useState(0);
    const [filter, setFilter] = useState('all');
    const [conn, setConn] = useState(false);
    const [voicePath, setVoicePath] = useState(false);
    const socket = io(process.env.REACT_APP_API_WSS);
    const navigate = useNavigate();

    useEffect(() => {
        socket.on('connect', () => {
            let user = Cookies.get('user')
            user = JSON.parse(user)
            const romId = user.room;
            socket.emit('join', { romId });
        });

        socket.on('updateStatus', (data) => {
            setAllData((prevAllData) => {
                const updatedScheduling = prevAllData.map(scheduling => {
                    if (scheduling.id === data.idScheduling) {
                        return { ...scheduling, status: parseInt(data.status) };
                    }
                    return scheduling;
                });
                return updatedScheduling;
            });
        });

        return () => {
            socket.disconnect();
        };
    }, []);
    const checkLogin = async () => {
        const logged = await isLogged();
        if (logged.statusCode == 200) {
            setConn(true);
        }
        
        setLoading(false);
    };

    const contacts = async () => {
        setLoading(true);
        const data = await getAllSchedulingItems(sendId);
        if (!data) {
            navigate('/send')
        }
        const scheduling = await getAllScheduling();
        const dataScheduling = scheduling.filter(scheduling => parseInt(scheduling.id) === parseInt(sendId));
        let user = Cookies.get('user');
        user = JSON.parse(user);
        setMaximumSender(parseInt(user.maximum_senders));
        setPlan(parseInt(user.plan));
        if (!data) {
            return [];
        }
        setButtonStatus(parseInt(dataScheduling[0]['status']));
        setMessage(dataScheduling[0]['message']);
        setImage(dataScheduling[0]['image'] ? dataScheduling[0]['image'] : false)
        setVoicePath(dataScheduling[0]['voicePath'] ? dataScheduling[0]['voicePath'] : false)
        setAllData(data);
    };

    useEffect(() => {
        contacts();
        checkLogin();
    }, [filter, buttonStatus]);

    const updateButton = async () => {
        let status;
        if (buttonStatus === 2) {
            status = 0;
        } else {
            status = 2;
        }
        const response = await updateScheduling({ status: status }, sendId);
        if (!response.status) {
            if (response.message == "You can't more than 2 scheduling") {
                alert('O maximo de campanha ativas simultaneamente é 2, para ativar essa campanha pause uma em execução');
                return
            }
            alert('Erro ao atualizar envio');
            return;
        }
        setButtonStatus(parseInt(status));
    };

    const deleteSend = async (id) => {
        const response = await deleteSchedulingItems(id);
        if (!response) {
            alert('Erro ao deletar envio');
            return;
        }

        const newDataSend = allData.filter((send) => send.id !== id);
        setAllData(newDataSend);
    };

    const exportToExcel = async () => {
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('Detalhes do Agendamento');

        // Add headers
        worksheet.columns = [
            { header: 'Contato', key: 'contact', width: 15 },
            { header: 'Status', key: 'status', width: 15 },
            { header: 'Data envio', key: 'data_send', width: 20 },
        ];

        // Add data
        allData.forEach((data) => {
            worksheet.addRow({
                contact: data.contact,
                status: parseInt(data.status) === 0 ? 'Pendente' : 'Enviado',
                data_send: data.data_send,
            });
        });

        // Create Excel file
        const buffer = await workbook.xlsx.writeBuffer();
        const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

        const fileName = 'detalhes_do_agendamento.xlsx';
        const href = URL.createObjectURL(blob);
        const link = document.createElement('a');

        link.href = href;
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const exportToTxt = () => {
        const txtContent = allData.map((data) => `${data.contact}`).join('\n');

        const blob = new Blob([txtContent], { type: 'text/plain' });
        const fileName = 'detalhes_do_agendamento.txt';
        const href = URL.createObjectURL(blob);
        const link = document.createElement('a');

        link.href = href;
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const filterData = () => {
        switch (filter) {
            case 'pending':
                return allData.filter(data => parseInt(data.status) === 0);
            case 'sent':
                return allData.filter(data => parseInt(data.status) === 1);
            default:
                return allData;
        }
    };

    return (
        <div className="container-fluid">
            {plan === 0 && maximumSender <= 0 && (
                <div className="alert alert-danger" role="alert">
                    Você já ultrapassou o limite de mensagem no plano gratuito, suas mensagens não serão mais enviadas, atualize para continuar usando
                </div>
            )}
            {(!conn && !loading) && (
                <div className="alert alert-warning" role="alert">
                    Para visualizar essa página você precisa estar logado no telegram. Suas mensagem não serão enviadas <a href="/home">clique aqui</a> para realizar o login
                </div>
            )}
            {conn &&
                <div className="col-lg-12 d-flex align-items-stretch">
                    <div className="card w-100">
                        <div className="card-body p-4 ">
                            <div className='content-info-send'>

                                <div className='container-title-card'>
                                    <h5 className="card-title fw-semibold mb-4">Detalhes do agendamento</h5>
                                    <div className='help-user'>
                                        <a target='_blank' href="https://www.youtube.com/@Platformbot-um5ok/videos" ><img className='help-img-yt' src="/assets/images/1384060.png" />Aprenda usar</a>
                                    </div>
                                </div>

                                <p>Obs: Iniciando o processo de envio, caso alguma data esteja antes a data atual, elas serão atualizadas para a data atual + o intervalo cadastrado</p>
                                <a href="/send" className="btn btn-outline-primary m-1">
                                    Voltar
                                </a>
                                {(plan === 0 && maximumSender > 0 || plan === 1) && (
                                    <span>
                                        <button className="btn btn-outline-primary m-1" onClick={exportToExcel}>
                                            Exportar para Excel
                                        </button>
                                        <button className="btn btn-outline-primary m-1" onClick={exportToTxt}>
                                            Exportar para TXT
                                        </button>
                                    </span>
                                )}
                                {message && (
                                    <div>
                                        <p className="mt-4 m-1">
                                            <b>Mensagem:</b>
                                        </p>
                                        <p className="mt-2 m-1">{message.split('\n').map((line, index) => <p key={index}>{line}</p>)}</p>
                                    </div>
                                )}
                                {image &&
                                    <div>
                                        <p className="mt-4 m-1">
                                            <b>Imagem:</b>
                                        </p>
                                        <img src={image} className="mt-2 m-1 image-preview" />
                                        <br></br>
                                        <span className="mr-1">*Após a conclusão do envio a imagem será excluida automaticamente</span>
                                    </div>
                                }
                                {voicePath &&
                                    <div>
                                        <p className="mt-4 m-1">
                                            <b>Audio:</b>
                                        </p>
                                        <audio controls>
                                            <source src={voicePath} type="audio/mp3" />
                                            Seu navegador não suporta a tag de áudio.
                                        </audio>
                                        <br></br>
                                        <span className="mr-1">*Após a conclusão do envio o audio será excluido automaticamente</span>
                                    </div>
                                }
                            </div>
                            <div className="table-responsive">
                                <div className='option-table-send'>
                                    {(!loading && (plan === 0 && maximumSender > 0 || plan === 1)) && (
                                        <button
                                            onClick={updateButton}
                                            type="button"
                                            disabled={buttonStatus === 1}
                                            className={
                                                buttonStatus === 0
                                                    ? 'btn btn-outline-success m-1 mt-4'
                                                    : buttonStatus === 2
                                                        ? 'btn btn-outline-warning m-1 mt-4'
                                                        : 'btn btn-outline-sucess m-1 mt-4'
                                            }
                                        >
                                            {buttonStatus === 0 ? 'Iniciar' : buttonStatus === 2 ? 'Parar' : 'Concluído'}
                                        </button>
                                    )}
                                    {!loading && (
                                        <div className="mt-4 m-1">
                                            <label className="fw-normal me-2">Filtrar:</label>
                                            <button
                                                className={`btn btn-outline-primary ${filter === 'all' && 'active'}`}
                                                onClick={() => setFilter('all')}
                                            >
                                                Todos
                                            </button>
                                            <button
                                                className={` m-1 btn btn-outline-danger ${filter === 'pending' && 'active'}`}
                                                onClick={() => setFilter('pending')}
                                            >
                                                Pendentes
                                            </button>
                                            <button
                                                className={`m-1 btn btn-outline-success ${filter === 'sent' && 'active'}`}
                                                onClick={() => setFilter('sent')}
                                            >
                                                Enviados
                                            </button>
                                        </div>
                                    )}
                                </div>
                                <p className='mt-4'>{loading && 'Carregando...'}</p>

                                {!loading && (
                                    <table className="table text-nowrap mb-0 align-middle">
                                        <thead className="text-dark fs-4">
                                            <tr>
                                                <th className="border-bottom-0">
                                                    <h6 className="fw-semibold mb-0">#id</h6>
                                                </th>
                                                <th className="border-bottom-0">
                                                    <h6 className="fw-semibold mb-0">Contato</h6>
                                                </th>
                                                <th className="border-bottom-0">
                                                    <h6 className="fw-semibold mb-0">Status</h6>
                                                </th>
                                                <th className="border-bottom-0">
                                                    <h6 className="fw-semibold mb-0">Data envio</h6>
                                                </th>
                                                <th className="border-bottom-0">
                                                    <h6 className="fw-semibold mb-0">Ações</h6>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {filterData().slice().reverse().map((data, index) => (
                                                <tr key={index}>
                                                    <td className="border-bottom-0">
                                                        <p className="mb-0 fw-normal">{data.id}</p>
                                                    </td>
                                                    <td className="border-bottom-0">
                                                        <p className="mb-0 fw-normal">{data.contact}</p>
                                                    </td>
                                                    <td className="border-bottom-0">
                                                        <div className="d-flex align-items-center gap-2">
                                                            <span
                                                                className={`badge bg-${(parseInt(data.status) === 0 || parseInt(data.status) === 3) ? 'danger' : 'success'
                                                                    } rounded-3 fw-semibold`}
                                                            >
                                                                {parseInt(data.status) === 1 ? 'Enviado' : parseInt(data.status) === 2 ? 'Preparando envio' : parseInt(data.status) === 3 ? 'Ocorreu um erro no envio' : 'Pendente'}
                                                            </span>
                                                        </div>
                                                    </td>
                                                    <td className="border-bottom-0">
                                                        <p className="mb-0 fw-normal">{data.data_send}</p>
                                                    </td>
                                                    <td className="border-bottom-0">
                                                        <button
                                                            onClick={() => deleteSend(data.id)}
                                                            className="btn btn-outline-danger m-1"
                                                        >
                                                            Delete
                                                        </button>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            }
        </div>
    );
};

export default SendView;
