import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import { useLocation } from 'react-router-dom';

const Header = () => {
    const [pro, setPro] = useState(false);
    const [loading, setLoading] = useState(true);
    const location = useLocation();
    const [scope, setScope] = useState(false); //deixar como false depois

    const checkPro = async () => {
        if (location.pathname == "/") {
            setPro(true)
            return;
        }
        let user = Cookies.get('user')
        user = JSON.parse(user)
        setPro(user.plan)
    }

    const scopeView = async () => {
        const queryParams = new URLSearchParams(location.search);
        const scopeQuery = queryParams.get('scope');
        if(scopeQuery) {
            Cookies.set('scope', scopeQuery)
            setScope(scopeQuery)
            return
        }
        
        const scopeView = Cookies.get('scope')
        if (scopeView) {
            setScope(scopeView)
            return;
        }
        setScope(false)
        setLoading(false)
    }

    useEffect(() => {
        checkPro()
        scopeView()
    }, [])

    return (
        <div>
            {!pro && <div class="card-title fw-semibold title-user-pro" role="alert">
                Você não pode visualizar todos contatos, para ter acesso a todas funcionalidade faça o upgrade, <a href="profile">clique aqui</a>
            </div>}
            {!pro && <div class="card-title fw-semibold title-user-pro" role="alert">
                Você não pode visualizar todos contatos, para ter acesso a todas funcionalidade faça o upgrade, <a href="profile">clique aqui</a>
            </div>}
            {(!scope && !loading) &&
            <div className="container-select-tools">
                <div className="content-select-tools">
                    <h1>Qual ferramenta você deseja utilizar?</h1>
                    <span>Ao selecionar a ferramenta, iremos preparar o ambiente para você. Fique à vontade para trocar a ferramenta a qualquer momento após a seleção.</span>
                    <div>
                        <div>
                            <a href="?scope=whatsapp"> <img src="../../assets/images/logos/whatsapp.png" /></a>
                        </div>
                        <div>
                            <a href="?scope=telegram"> <img src="../../assets/images/logos/telegram.png" /></a>
                        </div>
                    </div>
                </div>
            </div>
            }
            <header class="app-header">
                <nav class="navbar navbar-expand-lg navbar-light">
                    <ul class="navbar-nav">
                        <li class="nav-item d-block d-xl-none">
                            <a class="nav-link sidebartoggler nav-icon-hover" id="headerCollapse" href="javascript:void(0)">
                                <i class="ti ti-menu-2"></i>
                            </a>
                        </li>
                    </ul>
                    <div class="navbar-collapse px-0" id="navbarNav">
                        {scope &&
                        <ul class="navbar-nav">
                            <li class="nav-item dropdown">
                                <a class="nav-link" href="javascript:void(0)" id="drop2" data-bs-toggle="dropdown"
                                    aria-expanded="false">
                                    <img src={`../../assets/images/logos/${scope}.png`} alt="" width="35" height="35" class="rounded-circle" />                                    
                                    <span class="px-2 btn-tools-change"><span>Ferramentas</span>  <i class="ti ti-arrow-down fs-6"></i></span>
                                </a>
                                <div class="dropdown-menu dropdown-menu-end dropdown-menu-animate-up" aria-labelledby="drop2">
                                    <div class="message-body">
                                        <a href="?scope=whatsapp" class="d-flex align-items-center gap-2 dropdown-item">
                                            <p class="mb-0 fs-3">Whatsapp</p>
                                        </a>
                                        <a href="?scope=telegram" class="d-flex align-items-center gap-2 dropdown-item">
                                            <p class="mb-0 fs-3">Telegram</p>
                                        </a>
                                    </div>
                                </div>
                            </li>
                        </ul>
                        }
                    </div>
                    <div class="navbar-collapse justify-content-end px-0" id="navbarNav">
                        <ul class="navbar-nav flex-row ms-auto align-items-center justify-content-end">
                            <li class="nav-item dropdown">
                                <a class="nav-link nav-icon-hover" href="javascript:void(0)" id="drop2" data-bs-toggle="dropdown"
                                    aria-expanded="false">
                                    <img src="../../assets/images/profile/user-1.jpg" alt="" width="35" height="35" class="rounded-circle" />
                                </a>
                                <div class="dropdown-menu dropdown-menu-end dropdown-menu-animate-up" aria-labelledby="drop2">
                                    <div class="message-body">
                                        <a href="/profile" class="d-flex align-items-center gap-2 dropdown-item">
                                            <i class="ti ti-user fs-6"></i>
                                            <p class="mb-0 fs-3">Meu perfil</p>
                                        </a>
                                        <a href="/login" class="btn btn-outline-primary mx-3 mt-2 d-block">Sair</a>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </nav>
            </header>
        </div>

    )
}

export default Header