import React, { useEffect, useState } from 'react';
import getAllChatsGroups from '../../../helper/user/telegram/getGroups';
import { useParams } from 'react-router-dom';
import ExcelJS from 'exceljs';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';

const GroupsView = () => {
    const { groupId } = useParams();
    const [allContacts, setAllContacts] = useState([]);
    const [nameGroup, setNameGroup] = useState('');
    const [loading, setLoading] = useState(false);
    const [pro, setPro] = useState(false);
    const [offset, setOffset] = useState(null);  // Armazenar o último offset (ID do último contato carregado)
    const navigate = useNavigate();

    const checkPro = async () => {
        let user = Cookies.get('user')
        user = JSON.parse(user)
        setPro(user.plan)
    }

    const fetchContacts = async (offset = null) => {
        setLoading(true)
        setNameGroup(groupId);

        const data = {
            groupId: groupId,
            limit: 100,  
            offset: offset, 
        };

        let contacts = await getAllChatsGroups(data);

        const uniqueContacts = Array.from(new Map(contacts.data.map(contact => [contact.id, contact])).values());
        
        setAllContacts(prevContacts => [...prevContacts, ...uniqueContacts]);

        if (contacts.data.length > 0) {
            setOffset(contacts.data[contacts.data.length - 1].date);
        }
        setLoading(false)
    };

    useEffect(() => {
        fetchContacts();  
        checkPro();
    }, []);

    // Função para exportar IDs para TXT
    const exportIdToTxt = () => {
        const txtContent = allContacts
            .filter(contact => contact.id != null) // Ignora IDs null
            .map((contact) => `${contact.id}`).join('\n');

        const blob = new Blob([txtContent], { type: 'text/plain' });
        const fileName = `contatos_grupo_${nameGroup}_id.txt`;
        const href = URL.createObjectURL(blob);
        const link = document.createElement('a');

        link.href = href;
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    // Função para exportar Usernames para TXT
    const exportUsernameToTxt = () => {
        const txtContent = allContacts
            .filter(contact => contact.username != null) // Ignora Usernames null
            .map((contact) => `${contact.username}`).join('\n');

        const blob = new Blob([txtContent], { type: 'text/plain' });
        const fileName = `contatos_grupo_${nameGroup}_username.txt`;
        const href = URL.createObjectURL(blob);
        const link = document.createElement('a');

        link.href = href;
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    // Função para exportar Phones para TXT
    const exportPhoneToTxt = () => {
        const txtContent = allContacts
            .filter(contact => contact.phone != null) // Ignora Phones null
            .map((contact) => `${contact.phone}`).join('\n');

        const blob = new Blob([txtContent], { type: 'text/plain' });
        const fileName = `contatos_grupo_${nameGroup}_phone.txt`;
        const href = URL.createObjectURL(blob);
        const link = document.createElement('a');

        link.href = href;
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    // Função para exportar para Excel
    const exportToExcel = async () => {
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet(`Contatos do Grupo ${nameGroup}`);

        // Adicionar cabeçalhos
        worksheet.columns = [
            { header: 'Contato', key: 'contact', width: 15 },
            { header: 'Nome', key: 'name', width: 30 },
            { header: 'Data', key: 'date', width: 20 },
            { header: 'Username', key: 'username', width: 20 },
        ];

        // Adicionar dados
        allContacts.forEach((contact) => {
            worksheet.addRow({
                contact: contact.id,
                name: `${contact.firstName} ${contact.lastName ? contact.lastName : ''}`,
                date: new Date(contact.date * 1000).toLocaleString(), // Converting timestamp to human-readable format
                username: contact.username,
            });
        });

        // Criar o arquivo Excel
        const buffer = await workbook.xlsx.writeBuffer();
        const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

        const fileName = `contatos_grupo_${nameGroup}.xlsx`;
        const href = URL.createObjectURL(blob);
        const link = document.createElement('a');

        link.href = href;
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    return (
        <div className="container-fluid">
            {(!pro) && (
                <div className="alert alert-danger" role="alert">
                    Atenção alguns de seus contatos estão mascarado com "***" para remover assine um plano pro, <a href="/profile">clique aqui</a>
                </div>
            )}
            <div className="col-lg-12 d-flex align-items-stretch">
                <div className="card w-100">
                    <div className="card-body p-4">
                        <div className='container-title-card'>
                            <h5 className="card-title fw-semibold mb-4">Grupo: {nameGroup}</h5>
                            <div className='help-user'>
                                <a target='_blank' href="https://www.youtube.com/@Platformbot-um5ok/videos" ><img className='help-img-yt' src="/assets/images/1384060.png" />Aprenda usar</a>
                            </div>
                        </div>

                        {!loading && <div>
                            <button className="btn btn-outline-primary m-1" onClick={exportToExcel}>Exportar para Excel</button>
                            <button className="btn btn-outline-primary m-1" onClick={exportIdToTxt}>Exportar IDs para TXT</button>
                            <button className="btn btn-outline-primary m-1" onClick={exportUsernameToTxt}>Exportar Usernames para TXT</button>
                            <button className="btn btn-outline-primary m-1" onClick={exportPhoneToTxt}>Exportar Telefones para TXT</button>
                        </div>}

                        <div className="table-responsive">
                            <p>{loading && 'Carregando... aguarde... isso pode demorar dependendo da quantidade de dados'}</p>
                            
                                <table className="table text-nowrap mb-0 align-middle">
                                    <thead className="text-dark fs-4">
                                        <tr>
                                            <th className="border-bottom-0">
                                                <h6 className="fw-semibold mb-0">Contato</h6>
                                            </th>
                                            <th className="border-bottom-0">
                                                <h6 className="fw-semibold mb-0">Nome</h6>
                                            </th>
                                            <th className="border-bottom-0">
                                                <h6 className="fw-semibold mb-0">Username</h6>
                                            </th>
                                            <th className="border-bottom-0">
                                                <h6 className="fw-semibold mb-0">Telefone</h6>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {allContacts.map((contact, index) => (
                                            <tr key={index}>
                                                <td className="border-bottom-0">
                                                    <p className="mb-0 fw-normal">{contact.id}</p>
                                                </td>
                                                <td className="border-bottom-0">
                                                    <p className="mb-0 fw-normal">{contact.firstName} {contact.lastName ? contact.lastName : ''}</p>
                                                </td>
                                                <td className="border-bottom-0">
                                                    <p className="mb-0 fw-normal">{contact.username}</p>
                                                </td>
                                                <td className="border-bottom-0">
                                                    <p className="mb-0 fw-normal">{contact.phone ? contact.phone : ''}</p>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            {!loading && offset && (
                                <button className="btn btn-outline-primary m-3" onClick={() => fetchContacts(offset)}>
                                    Carregar mais
                                </button>
                            )}
                            {loading && (
                                <button className="btn btn-outline-primary m-3">
                                    carregando aguarde...
                                </button>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default GroupsView;
