import React, { useEffect, useState } from 'react';
import getAllScheduling from '../../../helper/user/getAllScheduling';
import deleteScheduling from '../../../helper/user/deleteScheduling';
import Cookies from 'js-cookie';
import isLogin from '../../../helper/user/telegram/isLogged';

const Send = () => {
    const [loading, setLoading] = useState(false);
    const [allData, setAllData] = useState([{}]);
    const [maximumSender, setMaximumSender] = useState(1);
    const [plan, setPlan] = useState(0);
    const [conn, setConn] = useState(false);

    const fetchSchedulingData = async () => {
        setLoading(true)
        let user = Cookies.get('user');
        user = JSON.parse(user);
        setMaximumSender(parseInt(user.maximum_senders));
        setPlan(parseInt(user.plan));
        const data = await getAllScheduling();
        if (!data) {
            return [];
        }

        setAllData(data);
    };

    const checkLogin = async () => {
        const logged = await isLogin();
        if (logged.statusCode == 200) {
            setConn(true);
        }
        
        setLoading(false)
    };

    useEffect(() => {
        fetchSchedulingData();
        checkLogin();
    }, []);

    const deleteSend = async (id) => {
        const response = await deleteScheduling(id);
        if (!response) {
            alert('Erro ao deletar envio');
            return;
        }

        const newDataSend = allData.filter((send) => send.id !== id);
        setAllData(newDataSend);
    };

    return (
        <div className="container-fluid">
            {loading && <span>carregando...</span>}
            {(!conn && !loading) && (
                <div className="alert alert-warning" role="alert">
                    Para visualizar essa página você precisa estar logado no telegram. Suas mensagens não serão enviadas <a href="/home">clique aqui</a> para realizar o login
                </div>
            )}
            {plan === 0 && maximumSender <= 0 && (
                <div className="alert alert-danger" role="alert">
                    Você já ultrapassou o limite de mensagem no plano gratuito, suas mensagens não serão mais enviadas, atualize para continuar usando
                </div>
            )}
            {
                !plan && (
                    <div className="alert alert-warning" role="alert">
                        Você possui <b>{maximumSender}</b> envios grátis, para envios ilimitados <a href="/profile">clique aqui</a>
                    </div>
                )
            }
            {conn &&
                <div className="col-lg-12 d-flex align-items-stretch">
                    <div className="card w-100">
                        <div className="card-body p-4">
                            <div className='container-title-card'>
                                <h5 className="card-title fw-semibold mb-4">Agendamento de envios</h5>
                                <div className='help-user'>
                                    <a target='_blank' href="https://www.youtube.com/@Platformbot-um5ok/videos" ><img className='help-img-yt' src="/assets/images/1384060.png" />Aprenda usar</a>
                                </div>
                            </div>

                            {!loading && (
                                <div>
                                    {(plan === 0 && maximumSender > 0 || plan === 1) && (
                                        <a href="/send/new" className="btn btn-outline-primary m-1">
                                            Criar envio
                                        </a>
                                    )}
                                </div>
                            )}
                            <div className="table-responsive">
                                <p>{loading && 'Carregando... '}</p>
                                {!loading && (
                                    <table className="table text-nowrap mb-0 align-middle">
                                        <thead className="text-dark fs-4">
                                            <tr>
                                                <th className="border-bottom-0">
                                                    <h6 className="fw-semibold mb-0">Nome</h6>
                                                </th>
                                                <th className="border-bottom-0">
                                                    <h6 className="fw-semibold mb-0">Data envio</h6>
                                                </th>
                                                <th className="border-bottom-0">
                                                    <h6 className="fw-semibold mb-0">Intervalo</h6>
                                                </th>
                                                <th className="border-bottom-0">
                                                    <h6 className="fw-semibold mb-0">Status</h6>
                                                </th>
                                                <th className="border-bottom-0">
                                                    <h6 className="fw-semibold mb-0">Ações</h6>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {allData.map((data, index) => (
                                                <tr key={index}>
                                                    <td className="border-bottom-0">
                                                        <p className="mb-0 fw-normal">{data.name}</p>
                                                    </td>
                                                    <td className="border-bottom-0">
                                                        <p className="mb-0 fw-normal">{data.date}</p>
                                                    </td>
                                                    <td className="border-bottom-0">
                                                        <p className="mb-0 fw-normal">{data.time}</p>
                                                    </td>
                                                    <td className="border-bottom-0">
                                                        <div className="d-flex align-items-center gap-2">
                                                            <span className={`badge bg-${parseInt(data.status) === 0 ? 'danger' : parseInt(data.status) === 2 ? 'primary' : 'success'} rounded-3 fw-semibold`}>
                                                                {parseInt(data.status) === 0 ? 'Pausado' : parseInt(data.status) === 2 ? 'Em andamento' : 'Concluído'}
                                                            </span>
                                                        </div>
                                                    </td>
                                                    <td className="border-bottom-0">
                                                        <a href={`send/${data.id}`} className="btn btn-outline-info m-1">
                                                            Ver
                                                        </a>
                                                        <a href={`send/edit/${data.id}`} className="btn btn-outline-warning m-1">
                                                            Edit
                                                        </a>
                                                        <button onClick={() => deleteSend(data.id)} className="btn btn-outline-danger m-1">
                                                            Delete
                                                        </button>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            }
        </div>
    );
};

export default Send;
