import Aside from '../../template/partial/aside'
import Header from '../../template/partial/header'
import SendViewWp from '../../template/whatsapp/send/sendView'
import SendViewTelegram from '../../template/telegram/send/sendView'
import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie';

const SendView = () => {
    const [scope, setScope] = useState('')
    useEffect(() => {
        const cookieScope = Cookies.get('scope') 
        setScope(cookieScope)
    })

    return (
        <div class="page-wrapper" id="main-wrapper" data-layout="vertical" data-navbarbg="skin6" data-sidebartype="full"
            data-sidebar-position="fixed" data-header-position="fixed">

            <Aside/>

            <div class="body-wrapper">
                <Header/>
                { scope == "whatsapp" && <SendViewWp/>}
                { scope == "telegram" && <SendViewTelegram/>}
            </div>

        </div>
    )
}

export default SendView